export const techHtml = {
	html: {
		url: 'https://www.w3schools.com/html/',
		src: '/assets/tech/html.svg',
	},
	css: {
		url: 'https://www.w3schools.com/css/',
		src: '/assets/tech/css.svg',
	},
	sass: {
		url: 'https://sass-lang.com/',
		src: '/assets/tech/sass.svg',
	},
	bootstrap: {
		url: 'https://getbootstrap.com/',
		src: '/assets/tech/bootstrap.svg',
	}
};


export const techJs = {
	javascript: {
		url: 'https://en.wikipedia.org/wiki/JavaScript',
		src: '/assets/tech/javascript.svg',
	},
	react: {
		url: 'https://reactjs.org/',
		src: '/assets/tech/react.svg',
	},
	gatsby: {
		url: 'https://www.gatsbyjs.org/',
		src: '/assets/tech/gatsby.svg',
	},
	graphql: {
		url: 'https://graphql.org/',
		src: '/assets/tech/graphql.svg',
	}
};

export const techDev = {
	gulp: {
		url: 'https://gulpjs.com/',
		src: '/assets/tech/gulp.svg',
	},
	webpack: {
		url: 'https://webpack.js.org/',
		src: '/assets/tech/webpack.svg',
	},
	git: {
		url: 'https://git-scm.com/',
		src: '/assets/tech/git.svg',
	},
	nodejs: {
		url: 'https://nodejs.org/en/',
		src: '/assets/tech/nodejs.svg',
	}
};

export const techWp = {
	php: {
		url: 'https://www.php.net/',
		src: '/assets/tech/php.svg',
	},
	wordpress: {
		url: 'https://wordpress.org/',
		src: '/assets/tech/wordpress.svg',
	},
	woocommerce: {
		url: 'https://woocommerce.com/',
		src: '/assets/tech/woocommerce.svg',
	},
	acf: {
		url: 'https://www.advancedcustomfields.com/',
		src: '/assets/tech/acf.svg',
	}
};

