import React from "react"
import Content from '../templates/content'
import { P } from '@bootstrap-styled/v4'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import SEO from '../components/Navigation/seo'
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import {techHtml, techJs, techDev, techWp } from '../data/technologies-list'
import Color from '@bootstrap-styled/color'


const list = { visible: { transition: { staggerChildren: 0.12, delayChildren: 0.5 } } };
const item = { hidden: { y: 20, opacity: 0 }, visible: { y: 0, opacity: 1 } };
const lightBg = Color('#202020').lighten(0.3).hex();

const printHTML = (string) => {return {__html: string};}

const List = styled(motion.ul)`
	text-align: center;
	li{
		display: inline-block;
		width: 4rem;
		img{
			max-width: 100%;
			height: auto;
		}
	}
	a{
		display: block;
		line-height: 1;
		padding: 0.5rem;
		transition: background-color 0.3s;
		&:hover{
			background-color: ${lightBg}
		}
	}
`;

const Desc = styled(P)`
	max-width: 38rem;
	margin: 0 auto 2rem;
	padding: 0 2rem;
`;

const AboutPage = () =>{ 
	const intl = useIntl();
	return (
		<Content h1={intl.formatMessage({ id: "o-mnie.title" })} h1class="mt-3 mt-md-0" hideOnMobile={true}>
			<SEO
				lang={intl.locale}
				title={intl.formatMessage({ id: "o-mnie.seoTitle" })}
				description={intl.formatMessage({ id: "o-mnie.seoDesc" })}
			/>
			<Desc dangerouslySetInnerHTML={printHTML(intl.formatMessage({ id: "o-mnie.desc_1" }))}></Desc>
			<Desc><FormattedMessage id="o-mnie.desc_2" /></Desc>
			<List animate="visible" initial="hidden" variants={list}>
				{Object.keys(techHtml).map((tech, index) => (
					<motion.li variants={item} key={index}><a href={techHtml[tech].url}><img src={techHtml[tech].src} alt={tech} /></a></motion.li>
				))}
			</List>
			<List animate="visible" initial="hidden" variants={list}>
				{Object.keys(techJs).map((tech, index) => (
					<motion.li variants={item} key={index}><a href={techJs[tech].url}><img src={techJs[tech].src} alt={tech} /></a></motion.li>
				))}
			</List>
			<List animate="visible" initial="hidden" variants={list}>
				{Object.keys(techDev).map((tech, index) => (
					<motion.li variants={item} key={index}><a href={techDev[tech].url}><img src={techDev[tech].src} alt={tech} /></a></motion.li>
				))}
			</List>
			<List animate="visible" initial="hidden" variants={list}>
				{Object.keys(techWp).map((tech, index) => (
					<motion.li variants={item} key={index}><a href={techWp[tech].url}><img src={techWp[tech].src} alt={tech} /></a></motion.li>
				))}
			</List>
		</Content>
	)
}

export default AboutPage
